.statistics * {
  box-sizing: border-box;
}

.statistics {
  display: grid;
  gap: 24px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;

  .statistics__title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .statistics__list {
    display: grid;
    gap: 16px;

    .statistics__list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20;

      .statistics__list-header-title {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .statistics__list-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      margin: 0 -8px;

      .card {
        box-sizing: border-box;
        padding: 16px 8px;
        margin: 0 8px;
        border-radius: 8px;
        background: #fff;
      }

      .card.booked-session {
        display: grid;
        justify-content: left;
        gap: 4px;
        max-width: calc(50% - 16px);
        width: 100%;
        height: 83px;
        text-align: left;

        .card-title {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
        }

        .card-description {
          font-size: 10px;
          font-weight: 400;
        }
      }

      .top-list {
        display: flex;
        justify-content: space-between;
        align-items: start;
        column-gap: 6px;
        margin: 0 8px;
        padding: 16px 8px;
        width: 100%;
        border-radius: 8px;
        background: #fff;

        .top-list-item {
          display: grid;
          justify-content: center;
          gap: 2px;

          .place {
            margin: 0;
            font-weight: 600;
          }

          .user {
            display: grid;
            justify-items: center;
            width: 100px;

            .user-avatar {
              width: 64px;
              height: 64px;
            }

            .user-name {
              margin-top: 4px;
              font-size: 12px;

              &.skeleton {
                width: 64px;
                height: 20px;
                background-color: #e5e5e5;
                border-radius: 12px;
              }
            }
          }

          .distribution-name {
            width: 100px;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2;
            word-wrap: break-word;

            &.skeleton {
              width: 100px;
              height: 20px;
              background-color: #e5e5e5;
              border-radius: 12px;
            }

          }


        }
      }

    }
  }

  .statistics__action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 12.5px;
    width: 100%;
    min-height: 42px;
    background: #000;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
  }
}



@media (min-width: 768px) {

  .statistics {
    .statistics__title {
      font-size: 24px;
    }

    .statistics__list {
      .statistics__list-header .statistics__list-header-title {
        font-size: 18px;
      }

      .statistics__list-content {

        .card {
          padding: 16px 24px;
        }

        .top-list {
          padding: 16px 24px;

          .top-list-item {
            gap: 8px;

            .user .user-name,
            .distribution-name {
              font-size: 14px;
            }

            .user,
            .distribution-name {
              min-width: 160px;
              width: 100%;
            }
          }
        }

        .card.booked-session {
          .card-title {
            font-size: 18px;
          }

          .card-description {
            font-size: 14px;
          }
        }
      }
    }


  }

}
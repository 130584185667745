
.create-user-form .create-user-form__avatar-upload {
    margin-bottom: 8px;
}

.create-user-form .arco-upload-trigger-picture {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    padding: 0;
    background: url("../../../assets/default-avatar.png") no-repeat center;
    background-size: 104px 104px;
    margin: auto;
}

.create-user-form .arco-upload-list-type-picture-card .arco-upload-list-item {
    margin: 0;
}

.create-user-form .arco-upload-list-item-picture {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    padding: 0;
}

.create-user-form .arco-select .arco-select-view,
.create-user-form .arco-textarea,
.create-user-form .arco-input {
    background: #ffffff;
}

.create-user-form .arco-textarea {
    border-radius: 8px;
}

.create-user-form .arco-form-layout-vertical > .arco-form-label-item {
    margin-bottom: 4px;
}

.create-user-form .arco-form-label-item > label {
    font-size: 12px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    color: #000000;
}

.create-user-form .arco-form-label-item .arco-form-item-symbol {
    color: #000000;
}
.create-user-form .arco-form-item-status-error .arco-select:not(.arco-select-disabled) .arco-select-view,
.create-user-form .arco-form-item-status-error .arco-input:not(.arco-input-disabled) {
    border-color: #BC0006;
    background: #ffffff;
}

.create-user-form .arco-form-item-status-error .arco-input:not(.arco-input-disabled):hover {
    border-color: #800206;
}

.create-user-form .arco-form-message {
    text-transform: capitalize;
    color: #BC0006;
    text-align: left;
}

.create-user-form .arco-input-tag .arco-input-tag-tag {
    background-color: #F2ECE2;
    color: #000000;
}


.create-user-form__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.create-user-form__footer-button {
    width: 144px;
}

.create-user-form__footer-button.create-user-form__primary-button.arco-btn {
    background: #000;
    color: #ffffff;
}

.create-user-form__footer-button.create-user-form__secondary-button.arco-btn {
    background: #A9A9A9;
    color: #ffffff;
}


@media (max-width: 768px) {
    .create-user-form__footer {
        justify-content: space-between;
    }
}
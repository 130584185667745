.confirmation-dialog {
    width: 328px;
    padding: 24px;
}

.confirmation-dialog__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    color: #000000;
}

.confirmation-dialog__button {
    width: 72px;
    font-weight: 600;
}

.confirmation-dialog__button.confirmation-dialog__button-secondary {
    background: #A9A9A9;
    color: #ffffff;
}

.confirmation-dialog .arco-modal-footer {
    margin-top: 24px;
}
.conversations-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.arco-btn.add-contact-button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.conversations-list-header .arco-radio-button.arco-radio-checked {
    color: #4d813c;
}

.horizontal-scrollable-container__wrapper {
    position: relative;
    overflow: hidden;
}

.horizontal-scrollable-container {
    padding: 0 16px 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: auto;
}

.horizontal-scrollable-container::-webkit-scrollbar {
    display: none;
}

.horizontal-scrollable-container__button {
    background: none;
    border: none;
    position: absolute;
    width: 40px;
    height: 100%;
    color: rgba(80, 80, 80, 0.5);
    cursor: pointer;
    top: 0;
}

.horizontal-scrollable-container__button.horizontal-scrollable-container__button-left {
    left: 0;
}

.horizontal-scrollable-container__button.horizontal-scrollable-container__button-right {
    right: 0;
}

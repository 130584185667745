@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alice&display=swap");

body {
  /*margin: 0;*/
  /*font-family: 'Montserrat', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.alice-regular {
  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;
}

.arco-input,
.arco-input-group,
.arco-input-inner-wrapper,
.arco-input-inner-wrapper-default,
.arco-select-view,
.arco-btn,
.arco-picker {
  --border-radius-small: 20px;
}

.arco-card {
  --border-radius-small: 8px;
}

.own-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px 0px,
    rgba(0, 0, 0, 0.3) 0px 0px 1px 0px;
}

.own-header {
  margin: 10px 0;
}

.own-header-logo img {
  padding: 8px 25px;
}

@media (max-width: 768px) {
  .own-header {
    margin: 0;
  }

  .own-header-logo img {
    padding: 0;
  }
}

.display-only-on-mobile {
  display: none;
}

.display-only-on-desktop {
  display: block;
}

@media (max-width: 768px) {
  .display-only-on-mobile {
    display: block;
  }

  .display-only-on-desktop {
    display: none;
  }
}

.arco-menu-selected-label {
  background-color: #000 !important;
  opacity: 0.5 !important;
  height: 1px !important;
  bottom: 2px !important;
}

.arco-spin .arco-spin-icon {
  color: #645841;
}

.sendbird-theme--dark .sendbird-channel-settings,
.sendbird-theme--light .sendbird-channel-settings,
.sendbird-theme--dark .sendbird-conversation,
.sendbird-theme--light .sendbird-conversation {
  border: none;
  margin-left: 8px;
  max-width: 472px;
  width: 100%;
}

@media (max-width: 768px) {

  .sendbird-theme--dark .sendbird-channel-settings,
  .sendbird-theme--light .sendbird-channel-settings,
  .sendbird-theme--dark .sendbird-conversation,
  .sendbird-theme--light .sendbird-conversation {
    margin-left: 0;
    max-width: unset;
    width: 100%;
    height: calc(100vh - 48px);
  }

  .sendbird-theme--dark .sendbird-channel-list,
  .sendbird-theme--light .sendbird-channel-list {
    max-width: unset;
    width: 100%;
    height: calc(100vh - 48px);
  }
}

.sendbird-message-input .sendbird-message-input--placeholder,
.sendbird-conversation .sendbird-message-input .sendbird-message-input--textarea {
  overflow: auto;
  text-align: left;
}

.sendbird-text-message-item-body {
  text-align: left;
  max-width: 90%;
}

.sendbird-theme--dark .sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at,
.sendbird-theme--light .sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
  position: static;
  flex-shrink: 0;
  width: 60px;
}

.sendbird-theme--dark .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container,
.sendbird-theme--light .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container {
  flex-direction: row;
}

.sendbird-theme--dark .sendbird-message-hoc__message-content.sendbird-message-content.incoming .sendbird-message-content__right,
.sendbird-theme--light .sendbird-message-hoc__message-content.sendbird-message-content.incoming .sendbird-message-content__right {
  display: none;
}

.sendbird-theme--dark .sendbird-message-hoc__message-content.sendbird-message-content.incoming:hover .sendbird-message-content__right,
.sendbird-theme--light .sendbird-message-hoc__message-content.sendbird-message-content.incoming:hover .sendbird-message-content__right {
  display: inline-flex;
  margin-right: 42px;
}

.sendbird-theme--dark .sendbird-label,
.sendbird-theme--light .sendbird-label,
.sendbird-theme--dark .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.outgoing,
.sendbird-theme--light .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.outgoing {
  width: 100%;
  height: 100%;
}

.sendbird-theme--light .sendbird-menu-item {
  justify-content: center;
}

.sendbird-theme--light .sendbird-menu-item .sendbird-label {
  width: fit-content;
}

.sendbird-theme--dark .sendbird-message-content.outgoing .sendbird-message-content__middle,
.sendbird-theme--light .sendbird-message-content.outgoing .sendbird-message-content__middle {
  min-width: 120px;
}

.sendbird-theme--dark .sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle. .sendbird-message-content__middle__sender-name,
.sendbird-theme--light .sendbird-conversation__messages[dir="ltr"] .sendbird-message-content.incoming .sendbird-message-content__middle. .sendbird-message-content__middle__sender-name {
  text-align: left;
}

.sendbird-theme--dark .sendbird-admin-message .sendbird-admin-message__text,
.sendbird-theme--light .sendbird-admin-message .sendbird-admin-message__text {
  justify-content: center;
}

.sendbird-theme--dark .sendbird-message-content__left.outgoing,
.sendbird-theme--light .sendbird-message-content__left.outgoing {
  flex-shrink: 0;
  width: 80px;
}

:root {
  --sendbird-light-primary-500: #645841 !important;
  --sendbird-light-primary-400: #9b8f7f !important;
  --sendbird-light-primary-300: #988c7a !important;
  --sendbird-light-primary-200: #e0dbd0 !important;
  --sendbird-light-primary-100: #fcf9f5 !important;
}

:root {
  --sendbird-dark-primary-500: #645841 !important;
  --sendbird-dark-primary-400: #9b8f7f !important;
  --sendbird-dark-primary-300: #988c7a !important;
  --sendbird-dark-primary-200: #e0dbd0 !important;
  --sendbird-dark-primary-100: #fcf9f5 !important;
}

:root {
  --sendbird-iconbutton-color: #645841 !important;
  --sendbird-message-input-border-active: #645841 !important;
  --sendbird-selected-reaction-button-border-hover: #f8f5ef !important;
  --sendbird-add-reaction-button-border-hover: #fcf9f5 !important;
}

.posts-actions-list .arco-list-item-action {
  display: flex;
  align-self: flex-start;
  margin-top: 6px;
}

.posts-actions-list .arco-comment-actions {
  display: flex;
  justify-content: left;
}

.arco-list-item-meta-avatar:not(:last-child) {
  margin-right: 0;
}

.arco-list-item-meta {
  .post-title {
    font-size: 16px;
  }
}

.arco-comment-content {
  font-size: 14px;
}

@media (max-width: 576px) {
  .arco-list-item-meta {
    flex-direction: column;
    gap: 12px;

    .post-title {
      font-size: 14px;
    }
  }

  .arco-comment-content {
    font-size: 12px;
  }
}

.arco-list .arco-list-default {
  overflow-y: auto;
  max-height: 400px;

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

}

.arco-list::-webkit-scrollbar {
  width: 6px;
}

.arco-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.arco-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.arco-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.arco-list::-webkit-scrollbar-button {
  display: none;
}

.arco-modal-footer .arco-btn-primary:not(.arco-btn-disabled) {
  background-color: #000 !important;
}

.arco-btn-primary {
  &:disabled {
    background-color: #888 !important;
  }
}

.arco-comment:not(:first-of-type) {
  margin-top: 10px !important;
}

.sub-comment {
  .arco-comment-inner {
    .arco-comment-inner-content {
      display: flex;
      justify-content: space-between;

      align-items: flex-start;
      gap: 24px;
      word-break: break-word;

      .arco-comment-content {
        margin-top: 8px;
      }
    }
  }
}

.feed-modal, .meeting-modal {
  &.arco-modal {
    max-width: 520px;
    width: 80%;
  }
}

.comments-list {
  list-style: "none";
  display: "grid";
  gap: 6;
  padding: 0;
  padding-left: 48px;
  margin: 0;

  @media (max-width: 767px) {
    padding-left: 6px;
  }
}

.post-actions {
  .arco-comment-actions-align-left {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
}
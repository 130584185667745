.user-profile {
    height: 100%;
}

.user-profile__header {
    margin: 0 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-profile__header-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-profile__header .user-profile__icon-button.arco-btn {
    background: none;
    border: none;
    padding: 4px;
}

.user-profile__title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.user-profile__action-buttons {
    display: flex;
    gap: 4px;
}

.user-profile__action-buttons .user-profile__icon-button.arco-btn {
    padding: 4px;
    background: none;
    width: 28px;
    height: 28px;
    color: #000000;
}

.user-profile__action-buttons .user-profile__icon-button.user-profile__icon-button--active.arco-btn {
    background: #000000;
    color: #ffffff;
}

.user-profile__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.user-profile__loader-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-profile__card.arco-card {
    padding: 0;
    border-radius: 8px;
}

.user-profile__card.arco-card .arco-card-body {
    padding: 0;
}

.user-profile__card.arco-card .user-profile__card-header {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding: 16px 16px 8px;
}

.user-profile__card-text-content {
    font-size: 12px;
    line-height: 1.4;
    text-align: left;
    color: #000000;
    padding: 0 16px 16px;
}

@media (max-width: 768px) {
    .user-profile {
        padding: 16px;
    }

    .user-profile__card.arco-card .user-profile__card-header {
        padding: 16px 8px 8px;
    }

    .user-profile__card-list.arco-list-default > .arco-list-content > .arco-list-item {
        padding: 8px;
        font-size: 10px;
    }

    .user-profile__card-text-content {
        font-size: 10px;
    }
}
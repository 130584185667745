.meetings__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.9;
    text-align: left;
    color: #000;
}

.meetings__content {
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    gap: 24px;
}

.meetings__day-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.9;
    text-align: left;
    color: #000;
    margin: 0 0 16px;
}

.meetings__day-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media (max-width: 768px) {
    .meetings {
        padding: 0 16px;
    }
}

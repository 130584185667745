.conversation-preview {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #f0f0f0;
    background: #ffffff;
    width: 100%;
}

.conversation-preview_selected {
    background-color: #f8f5ef;
}

.conversation-preview__avatar {
    flex-shrink: 0;
}

.conversation-preview__info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 4px;
    text-align: left;
    width: 256px;
}

.conversation-preview__top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.conversation-preview__name {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    gap: 4px;
}

.conversation-preview__unread-count {
    background-color: #d24e4e;
    min-width: 10px;
    width: auto;
    height: 12px;
    border-radius: 10px;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding: 2px 4px;
}

.conversation-preview__last-message {
    color: #666;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.conversation-preview__last-message-time {
    color: #666;
    font-size: 12px;
}

@media (max-width: 768px) {
    .conversation-preview {
        padding: 16px;
    }
    .conversation-preview__info {
        width: 100%;
    }
}
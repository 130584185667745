
.conversation__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0;
}

.conversation__header-button.arco-btn {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.conversation__info {
    display: flex;
    flex-direction: row;
    gap: 12px;
    text-align: left;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .card-header__title {
    margin: 0;
    text-align: left;
    word-break: break-word;
    text-transform: uppercase;
  }


}

.posts-list {
  width: 100%;
  height: 590px;

  .arco-list-item-main {
    margin-right: 12px;
  }
}
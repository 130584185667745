.success-screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-screen__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
    text-align: center;
    margin: 0 0 32px;
}

.success-screen__button-wrapper {
    width: 100%;
    margin-top: 90px;
}

.success-screen__button-wrapper .success-screen__button.arco-btn {
    width: 100%;
    max-width: 318px;
    background: #000;
    color: #ffffff;
}
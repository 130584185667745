.users-list {
    padding: 16px 0;
}

.users-list .arco-input-group-wrapper .arco-input-inner-wrapper {
    background: #ffffff;
}

.users-list__header {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 0 0 8px;
}

.users-list__filter-wrapper {
    text-align: left;
    margin-bottom: 16px;
}

.users-list__search {
    margin-bottom: 24px;
}

.users-list__checkboxes .arco-checkbox {
    padding: 0;
}

.users-list__filter-button {
    text-transform: capitalize;
}

.users-list .users-list__filter-button.arco-btn-primary {
    background: #000000;
    color: #ffffff;
}

.users-list .users-list__filter-button.arco-btn-secondary {
    background: #bbbaba;
    color: #ffffff;
}

.users-list .users-list__filter-button.arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover,
.users-list .users-list__filter-button.arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    background: #424242;
    color: #ffffff;
}

.users-list__list.arco-list-default > .arco-list-content > .arco-list-item {
    padding: 0;
    border: none;
    margin-bottom: 8px;
}

.users-list__list.arco-list-default > .arco-list-content > .arco-list-item:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
    .users-list {
        padding: 16px;
    }
}
.create-user {
    height: 100%;
}

.create-user__title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.create-user__form-wrapper {
    margin-top: 28px;
}

@media (max-width: 768px) {
    .create-user {
        padding: 16px;
    }
}
.create-user__dropdown-trigger.arco-btn:not(.arco-btn-disabled) {
    padding: 4px 8px 4px 8px;
    border-radius: 16px;
    border: 1px solid #000000;
    color: #000000;
    font-size: 12px;
    line-height: 1.4;
    text-align: left;
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: capitalize;
}

.create-user__dropdown-trigger.arco-btn:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    border: 1px solid #424242;
    color: #424242;
}

.role-dropdown__option {
    text-transform: capitalize;
}

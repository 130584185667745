.meeting-card.arco-card {
    border-radius: 8px;
}

.meeting-card__name {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 4px;
}

.meeting-card__date-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.meeting-card__time,
.meeting-card__week-day {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000;
}

.meeting-card__date {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    color: #000;
}

.meeting-card__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.meeting-card__button.arco-btn  {
    width: 120px;
    font-weight: 600;
}

.meeting-card__button.meeting-card__button-primary.arco-btn {
    color: #ffffff;
    background: #000000;
}
.meeting-card__button.meeting-card__button-primary.arco-btn:disabled {
    color: #fff;
    background: #555;
}

.meeting-card__button.meeting-card__button-secondary.arco-btn {
    background: #A9A9A9;
    color: #ffffff;
}

@media (max-width: 768px) {
    .meeting-card__name {
        font-size: 12px;
        line-height: 14px;
    }

    .meeting-card__time,
    .meeting-card__week-day {
        font-size: 12px;
        line-height: 14px;
    }

    .meeting-card__date {
        font-size: 16px;
        line-height: 18px;
    }

    .meeting-card__buttons {
        margin-top: 16px;
    }

    .meeting-card__button.arco-btn  {
        width: 96px;
    }
}

.admin-banner__header {
    display: flex;
    justify-content: end;
}

.admin-banner .admin-banner__icon-button.arco-btn {
    background: none;
    border: none;
    padding: 4px;
}

.admin-banner .admin-banner__icon-button.arco-btn {
    padding: 4px;
    background: none;
    width: 28px;
    height: 28px;
    color: #000000;
}

.admin-banner .admin-banner__icon-button.admin-banner__icon-button--active.arco-btn {
    background: #000000;
    color: #ffffff;
}

.admin-banner__upload-wrapper {
    margin-top: 16px;
}

.admin-banner .arco-upload-list-item-picture {
    width: 100%;
    height: auto;
    border-radius: 16px;
}

.admin-banner .arco-upload-trigger-picture {
    width: 100%;
    height: 420px;
    border-radius: 16px;
    background: #ffffff url("../../../../assets/icons/gallery-add.svg") no-repeat center;
}

.admin-banner .arco-upload-trigger-picture-text > svg {
    display: none;
}


.admin-banner__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 32px;
}

.admin-banner__button.arco-btn  {
    width: 280px;
    font-weight: 600;
}

.admin-banner__button.admin-banner__button-primary.arco-btn {
    color: #ffffff;
    background: #000000;
}

.admin-banner__button.admin-banner__button-secondary.arco-btn {
    background: #A9A9A9;
    color: #ffffff;
}

.admin-banner__image-wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.admin-banner__image {
    width: 100%;
}

@media (max-width: 768px) {
    .admin-banner {
        padding: 0 16px;
    }


    .admin-banner .arco-upload-trigger-picture {
        height: 177px;
    }

    .admin-banner__buttons {
        margin-top: 16px;
        gap: 16px;
    }

    .admin-banner__button.arco-btn {
        width: 144px;
    }
}

.booking-form__header {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding: 16px 16px 8px;
}


.booking-form__slot-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #A9A9A9;
    background-color: #ffffff;

    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    color: #A9A9A9;

    cursor: pointer;
}

.booking-form__slot-card.booking-form__slot-card-selected {
    border-color: #387CE2;
    color: #387CE2;
}

.booking-form__date {
    font-weight: 600;
}

.booking-form__time-card {
    padding: 8px 6px;
    border-radius: 8px;
    border: 1px solid #A9A9A9;
    background-color: #ffffff;

    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    color: #A9A9A9;

    cursor: pointer;
    white-space: nowrap;
}

.booking-form__time-card.booking-form__time-card-selected {
    border-color: #387CE2;
    color: #387CE2;
}

.booking-form__no-slots,
.booking-form__slots-loader-wrapper {
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-form__time-loader-wrapper {
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 768px) {
    .booking-form__header {
        padding: 16px 8px 8px;
    }

    .booking-form__slots {
        padding: 0 8px 16px;
    }
}
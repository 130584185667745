.user-card.arco-card {
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
}

.user-card.arco-card.user-card__with-shadow {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.07);
}

.user-card.arco-card.user-card__with-shadow:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
}

.user-card.arco-card .arco-card-body {
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 16px;
    text-align: left;
    color: #000000;
}

.user-card__avatar {
    flex-shrink: 0;
}

.user-card__card-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
}

.user-card__card-info__general-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    min-width: 150px;
}

.user-card__name {
    font-size: 14px;
    font-weight: 600;
}

.user-card__info-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    font-size: 12px;
}

.user-card__location {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.user-card_categories {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    flex-grow: 1;
}

.user-card__category-tag.arco-tag {
    border-radius: 8px;
    padding: 2px 8px;
    font-size: 10px;
    background-color: #F2ECE2;
    color: #000000;
}

.user-card__action-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.user-card__action-buttons .user-card__icon-button.arco-btn {
    padding: 0;
    background: none;
    width: 24px;
    height: 24px;
}

.user-card__action-buttons .user-card__icon-button--primary.arco-btn {
    color: #ffffff;
    background: #000000;
    padding: 2px;
}

@media (max-width: 768px) {
    .user-card__card-info {
        flex-direction: column;
    }

    .user-card__list .user-card__card {
        padding: 16px 8px;
    }

    .user-card__card-info__general-info {
        min-width: unset;
    }

    .user-card__info-row {
        flex-direction: row;
        gap: 16px;
    }
}
.user-booking {
    height: 100%;
}

.user-booking__header {
    margin: 0 0 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
}

.user-booking__header .user-booking__icon-button.arco-btn {
    background: none;
    border: none;
    padding: 4px;
}

.user-booking__title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.user-booking__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.user-booking__loader-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-booking__label {
    font-size: 12px;
    line-height: 1.4;
    text-align: left;
    color: #000000;
    margin-bottom: 4px;
}

.user-booking__card.arco-card {
    padding: 0 0 16px 0;
    border-radius: 8px;
}

.user-booking__card.arco-card .arco-card-body {
    padding: 0;
}

.user-booking__card.arco-card .user-booking__card-header {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding: 16px 16px 8px;
}

.user-booking__autocomplete-wrapper {
    padding: 0 16px;
}

.user-booking__autocomplete-dropdown {
    max-height: 300px;
    overflow: auto;
    background-color: #ffffff;
}

.user-booking__autocomplete-option {
    background: none;
    border: none;
    width: 100%;
    margin: 4px 0;
}

.user-booking__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.user-booking__footer-button {
    width: 144px;
}

.user-booking__footer-button.user-booking__primary-button.arco-btn {
    background: #000;
    color: #ffffff;
}

.user-booking__footer-button.user-booking__secondary-button.arco-btn {
    background: #A9A9A9;
    color: #ffffff;
}

.user-booking__success {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-booking__success-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
    text-align: center;
    margin: 0 0 32px;
}

.user-booking__success-button-wrapper {
    width: 100%;
    margin-top: 90px;
}

.user-booking__success-button-wrapper .user-booking__footer-button.arco-btn {
    width: 100%;
    max-width: 318px;
}

@media (max-width: 768px) {
    .user-booking {
        padding: 16px;
    }

    .user-booking__card.arco-card .user-booking__card-header {
        padding: 16px 8px 8px;
    }

    .user-booking__autocomplete-wrapper {
        padding: 0 8px;
    }
}
.conversation-report-dialog__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.conversation-report-dialog__category-option {
    text-transform: capitalize;
}

@media (max-width: 768px) {
    .arco-modal.conversation-report-dialog {
        width: 100%;
        height: 100%;
    }
}
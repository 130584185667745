.admin-panel {
    padding: 32px 0;
}

@media (max-width: 768px) {
    .admin-panel {
        padding: 40px 16px;
    }
}

.admin-panel-card.arco-card {
    margin: auto;
    padding: 16px;
    border-radius: 16px;
    height: 124px;
    width: 124px;
    cursor: pointer;
    background-size: 64px 64px;
    background-position: 16px 67px;
    background-repeat: no-repeat;
    background-color: #ffffff;
}

.admin-panel-card.arco-card:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.admin-panel-card.arco-card.admin-panel-card__user-list {
    background-image:  url("../../../../assets/admin-panel/user-list.png");
}

.admin-panel-card.arco-card.admin-panel-card__create-user {
    background-image: url("../../../../assets/admin-panel/create-user.png");
}

.admin-panel-card.arco-card.admin-panel-card__meetings {
    background-image: url("../../../../assets/admin-panel/meetings.png");
}

.admin-panel-card.arco-card.admin-panel-card__statistics {
    background-image: url("../../../../assets/admin-panel/statistics.png");
}

.admin-panel-card.arco-card .arco-card-body {
    text-align: left;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

@media (max-width: 359px) {
    .admin-panel-card.arco-card {
        height: 100px;
        width: 100px;
    }
}

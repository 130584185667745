.user-detailed-info.arco-list-default {
    border: none;
    padding-bottom: 16px;
}

.user-detailed-info.arco-list-default > .arco-list-content > .arco-list-item {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 8px 16px;
}

.user-detailed-info.arco-list-default > .arco-list-content > .arco-list-item:first-child {
    border-top: none;
}

@media (max-width: 768px) {
    .user-detailed-info.arco-list-default > .arco-list-content > .arco-list-item {
        padding: 8px;
        font-size: 10px;
    }
}
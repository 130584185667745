.start-conversation-dialog.arco-modal {
    width: 400px;
    height: 400px;
    overflow: hidden;
}

.start-conversation-dialog__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.start-conversation-dialog__no-users-message {
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start-conversation-dialog__blocked-user {
    font-size: 12px;
    color: #e39c7c;
    padding-left: 8px;
}